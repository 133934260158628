import moment from 'moment';

import { ExportType } from '../app/pages/interfaces';

export enum Resources {
    Transactions = 'transactions',
}

export const EXPORT_TYPES = [
    { name: 'xlsx', value: 'xlsx' },
    { name: 'csv', value: 'csv' },
];

export const MONTHLY_DAYS_NUMBER_VALUES = Array.from({ length: 31 }, (_, i) => ({
    number: i + 1,
    value: String(i + 1),
}));

export const MONTH_NAMES = [
    'january',
    'february',
    'march',
    'april',
    'may',
    'june',
    'july',
    'august',
    'september',
    'october',
    'november',
    'december',
];

export enum DailyOptions {
    AllDayOption = 'daily_option',
    DayNameOption = 'day_name_option',
}

export const DEFAULT_TIME = moment({ hour: 0, minute: 0 }).toDate();
export const DEFAULT_WORKING_DAY = ['MON'];
export const DEFAULT_DAY_NUMBER = '1';
export const DEFAULT_MONDAY_NUMBER = '2';
export const DEFAULT_MONTH = '1';

const DEFAULT_EXPORT_TYPE: ExportType = 'xlsx';
const DEFAULT_REPEAT_VALUE = 'weekly';

export const DEFAULT_SCHEDULER_VALUE = '*';
export const DAY_IGNORE_VALUE = '?';

export const DEFAULT_REPORT_INITIAL_VALUES = {
    day_number: DEFAULT_MONDAY_NUMBER,
    time: DEFAULT_TIME,
    working_days: DEFAULT_WORKING_DAY,
    day_time: DEFAULT_TIME,
    month: DEFAULT_MONTH,
    repeat: DEFAULT_REPEAT_VALUE,
    export_type: DEFAULT_EXPORT_TYPE,
    state: false,
};
