import { DefaultFilters } from '../app/common/interfaces';

export const TRANSACTION_STATE_FIELD = 'state';
// default filters that are applied for transactions
export const INITIAL_TRANSACTION_FILTERS: DefaultFilters = {
    filter: {
        logic: 'and',
        filters: [{ field: TRANSACTION_STATE_FIELD, operator: 'eq', value: 'completed' }],
    },
};
export const TRANSACTION_STATES = [
    'created',
    'pending',
    'consumed',
    'declined',
    'completed',
    'error',
];
